import React, { useState } from "react";
import Layout from "../components/Layout";
import Breadcrumb from "../components/Breadcrumb";
import BookTabDetail from "../components/ProductTab/ProductTabDetail";
import { recommendedList } from "../data/recommendedList"
import { images } from "../data/book-tab-carousel"
import Slider from "react-slick";
import ProductCard from "../components/ProductCard";
import { Helmet } from "react-helmet";

export default function BookTab() {
	const path = [
		{ label: "Inici", to: "/" },
		{ label: "Llar", to: "/llar" },
		{ label: "Gerro Inka rosa" },
	];

	const tabs = [
		{ title: 'Descripció' },
		{ title: 'Detalls' },
	]

	const [open, setOpen] = useState(false)

	const toggle = () => setOpen(!open)

	const SampleNextArrow = (props) => {
		const { onClick } = props;
		return (
			<button
				className="Carousel-btn-right"
				onClick={onClick}
			>
				<i className="i-custom-arrow" />
			</button>
		);
	}

	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		nextArrow: <SampleNextArrow />,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					initialSlide: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			}
		]
	};

	const renderCarouselItems = () => {
		return recommendedList && recommendedList.map((item) => {
			return (
				<div>
					<ProductCard
						image={item.image}
						price={item.price}
						name={item.name}
					/>
				</div>
			)
		})
	}

	return (
		<Layout>
			<Helmet>
				<link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
				<link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
			</Helmet>
			<div className="BookTab">
				<div className="BookTab-container">
					<Breadcrumb className="BookTab-breadcrumb" path={path} />
					<BookTabDetail
						images={images}
						title="LLIBRE PAINTING BEYOND POLLOCK"
						author="Morgan Falconer"
						price="40,00 €"
						description="Una història viva i accesible de la pintura europea i americana desde mitjans del segle XX cap al davant. La pintura, amb la seva inesgotable capacitat de reinvenció, segueix ocupant un lloc privilegiat en l’art occidental.
                        Desde mitjans del seglo XX, noves practiques han portat a l’art a territoris com las performance i la instal·lació, el que ha fet que alguns crítics i artistas declaressin que la pintura era irrelevant o esgotada. Pero aquests desenvolupaments, de fet, han portat a la pintura a noves alçades d’innovació i interés, fent d’aquests setanta anys possiblement els més animats de la seva història. "
						editorial="RBA LIBROS"
						language="ANGLÈS"
						publication_edition="2019"
						page_number="448"
						isbn="9788491873198"
						tabs={tabs}
						toggle={toggle}
						open={open}
						isObject={false}
					/>
				</div>
				<section className="BookTab-recommended">
					<h3 className="BookTab-recommended-title">
						També et pot agradar…
					</h3>
					<div className="BookTab-carousel">
						{/* <Carousel slides={recommendedList} scrollInitialValue={3}/> */}
						<Slider className="Novelties-slick" {...settings}>
							{renderCarouselItems()}
						</Slider>
					</div>
				</section>
			</div>
		</Layout>
	);
}
